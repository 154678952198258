import axios, { AxiosRequestHeaders } from 'axios'
import { useRecoilValue } from 'recoil'
import { ACCESS_TOKEN } from 'src/consts'
import { TripType } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { isDev } from 'src/helpers/utils'
import { lineState } from 'src/states'
import {
  BookerContact,
  Booking,
  Bookings,
  BookingVehicle,
  CarAvailable,
  CarModel,
  CreateBookingResponse,
  DriverLocation,
  IBooking,
  IConsent,
  IFeedback,
  IRequestTrial,
  PaymentMethod,
  Place,
  PostFeedback,
  PublicBooking,
  Questions,
  Route,
  SigninData,
  UpdateCustomer,
  CreateCustomer,
  Vehicle,
  CustomerCNMIInterface,
  CustomerAddressCNMIInterface,
  BookerContactCNMI,
} from 'src/types'

export function useCallApi() {
  const { userId } = useRecoilValue(lineState)
  const baseUrl = process.env.REACT_APP_BASE_API_URL

  function generateHeader(): AxiosRequestHeaders {
    let accessToken = ''
    if (isDev) {
      accessToken = process.env.REACT_APP_API_ACCESS_TOKEN || ''
    } else if (userId) {
      accessToken = localStorage.getItem(ACCESS_TOKEN) || ''
    }
    return { Authorization: `Bearer ${accessToken}` }
  }

  // Authentication ------------------------------------------------------------

  async function signin(dataInput: SigninData): Promise<string> {
    // async function signin(userId: string): Promise<string> {
    const url = `${baseUrl}/liff-auth/signin`
    const { data } = await axios.post(url, dataInput)
    return data
  }

  // Booking -------------------------------------------------------------------

  async function getBookings(params?: object): Promise<Bookings | null> {
    const url = `${baseUrl}/liff-bookings`
    const headers = generateHeader()
    const config: object = {
      method: 'get',
      url,
      headers,
      params,
    }
    const { data } = await axios(config)
    return data
  }

  async function getBooking(bookingNumber: string): Promise<BookingVehicle | null> {
    const url = `${baseUrl}/liff-bookings/${bookingNumber}`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers })
    const b = {
      ...data,
      departureTime: dayjstz(data.departureTime).toDate(),
      arrivalTime: dayjstz(data.arrivalTime).toDate(),
      departureTime2: data.departureTime2 ? dayjstz(data.departureTime2).toDate() : undefined,
      arrivalTime2: data.arrivalTime2 ? dayjstz(data.arrivalTime2).toDate() : undefined,
    }
    return b
  }

  async function getBookingList(params?: object): Promise<Bookings | null> {
    const url = `${baseUrl}/bookinglist`
    const headers = generateHeader()
    const config: object = {
      method: 'get',
      url,
      headers,
      params,
    }
    const { data } = await axios(config)
    return data
  }

  async function checkSlotAvailability(
    userId: string,
    formattedDate: string,
    currentBookingLocation: { lat: number; lng: number }
  ): Promise<{ isGoing: boolean; isAvailable: boolean; time: { hour: number; minute: number } }[]> {
    // Construct the request body with the relevant time slot data and current booking location
    const requestBody = {
      currentUserId: userId,
      date: formattedDate,
      currentLocation: currentBookingLocation,
    }
    const headers = generateHeader()

    // Use POST instead of GET
    const { data } = await axios.post(`${baseUrl}/liff-bookings/checkslotavailability`, requestBody, { headers })
    return data.timeSlots
  }

  async function getPublicBooking(
    bookingNumber: string,
    domain: string,
    destination?: string
  ): Promise<PublicBooking | null> {
    const url = `${baseUrl}/public-bookings/${bookingNumber}${domain ? `?domain=${domain}` : ''}${
      destination ? `&destination=${destination}` : ''
    }`
    const { data } = await axios.get(url)
    return data
  }

  async function createBooking(booking: IBooking): Promise<CreateBookingResponse> {
    const url = `${baseUrl}/liff-bookings`
    const headers = generateHeader()
    const b = {
      ...booking,
      scheduledDate: dayjstz(booking.scheduledDate).toISOString(),
      departureTime: dayjstz(booking.departureTime).toISOString(),
      arrivalTime: dayjstz(booking.arrivalTime).toISOString(),
      departureTime2:
        booking.tripType === TripType.ROUND_TRIP ? dayjstz(booking.departureTime2)?.toISOString() : undefined,
      arrivalTime2: booking.tripType === TripType.ROUND_TRIP ? dayjstz(booking.arrivalTime2)?.toISOString() : undefined,
    }
    const { data } = await axios.post(url, b, { headers })
    return data
  }

  async function createRealtimeBooking(booking: IBooking): Promise<CreateBookingResponse> {
    const url = `${baseUrl}/liff-bookings/realtime`
    const headers = generateHeader()
    const b = {
      ...booking,
      scheduledDate: dayjstz(booking.scheduledDate).toISOString(),
      departureTime: dayjstz(booking.departureTime).toISOString(),
      arrivalTime: dayjstz(booking.arrivalTime).toISOString(),
      departureTime2:
        booking.tripType === TripType.ROUND_TRIP ? dayjstz(booking.departureTime2)?.toISOString() : undefined,
      arrivalTime2: booking.tripType === TripType.ROUND_TRIP ? dayjstz(booking.arrivalTime2)?.toISOString() : undefined,
    }
    const { data } = await axios.post(url, b, { headers })
    return data
  }

  async function updateBooking(booking: Booking): Promise<boolean> {
    const url = `${baseUrl}/liff-bookings`
    const headers = generateHeader()
    const b = {
      ...booking,
      scheduledDate: dayjstz(booking.scheduledDate).toISOString(),
      departureTime: dayjstz(booking.departureTime).toISOString(),
      arrivalTime: booking.arrivalTime?.toISOString(),
      departureTime2: booking.tripType === TripType.ROUND_TRIP ? booking.departureTime2?.toISOString() : undefined,
      arrivalTime2: booking.tripType === TripType.ROUND_TRIP ? booking.arrivalTime2?.toISOString() : undefined,
    }
    const { data } = await axios.put(url, b, { headers })
    return data
  }

  async function updatePassengerLineId(bookingNumber: string, language: string, passengerLineId: string) {
    const url = `${baseUrl}/liff-bookings/${bookingNumber}/passenger-line-id`
    const headers = generateHeader()
    const { data } = await axios.put(url, { passengerLineId, language }, { headers })
    return data.message
  }

  async function cancelBooking(bookingNumber: string) {
    const url = `${baseUrl}/liff-bookings/${bookingNumber}/cancel`
    const headers = generateHeader()
    const { data } = await axios.put(url, {}, { headers })
    return data
  }

  async function getStartStopCarServiceTime() {
    const headers = generateHeader()
    const url = `${baseUrl}/liff-hospital/serviceTime`
    const { data } = await axios.get(url, { headers })
    return data
    // return {
    //   startCarServiceTime: '08:30',
    //   stopCarServiceTime: '20:30',
    // }
  }

  async function getCutOffAdvanceBookingTime() {
    const headers = generateHeader()
    const url = `${baseUrl}/liff-hospital/cutOffTime`
    const { data } = await axios.get(url, { headers })
    return data
    // return {
    //   "cutOffAdvanceBookingTime": "23:30"
    //   }
  }

  async function getRealtimeStatus(date: Date) {
    const headers = generateHeader()

    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`

    const url = `${baseUrl}/liff-cars/is-vehicles-real-time-available?targetDay=${formattedDate}`
    const { data } = await axios.get(url, { headers })
    // console.log(`getRealtime : ${formattedDate}`)
    return data
    // if(formattedDate === '2023-05-30') {
    //   return true
    // }
    // return false
  }

  async function getSupportPostcode() {
    const headers = generateHeader()
    const url = `${baseUrl}/liff-hospital/supportPostcode`
    const { data } = await axios.get(url, { headers })
    return data
  }

  async function getSupportProvince() {
    const headers = generateHeader()
    const url = `${baseUrl}/liff-hospital/supportProvince`
    const { data } = await axios.get(url, { headers })
    return data
  }

  async function getNormalizeProvince(province: string) {
    const headers = generateHeader()
    const url = `${baseUrl}/public-api/normalizeProvince?province=${province}`
    const { data } = await axios.get(url, { headers })
    return data
  }

  // Car -----------------------------------------------------------------------

  async function getCountActive(date: string) {
    const url = `${baseUrl}/liff-cars/count-active?date=${date}`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers })
    // const temp = { car: 10, vehicle: 0 }
    return data
  }

  async function getVehicles(date: string): Promise<Vehicle[]> {
    const url = `${baseUrl}/liff-cars/vehicles?date=${date}`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers })
    return data
  }

  async function getAvailableVehicles(
    date: string,
    tripType: number,
    routeGo: Route,
    goDepartureTime: Date,
    goArrivalTime: Date,
    routeBack?: Route,
    backDepartureTime?: Date,
    backArrivalTime?: Date
  ): Promise<CarAvailable[]> {
    // console.log(date, tripType, routeGo, goDepartureTime, goArrivalTime, routeBack, backDepartureTime, backArrivalTime)
    const url = `${baseUrl}/liff-cars/available-vehicles`
    const body = {
      date,
      tripType,
      routeGo,
      goDepartureTime: goDepartureTime.toISOString(),
      goArrivalTime: goArrivalTime.toISOString(),
      routeBack,
      backDepartureTime: backDepartureTime ? backDepartureTime.toISOString() : undefined,
      backArrivalTime: backArrivalTime ? backArrivalTime?.toISOString() : undefined,
    }
    const headers = generateHeader()
    const { data } = await axios.post(url, body, { headers })
    return data
    // return [
    //   {
    //     vehicle: {
    //       id: 180,
    //       date: '2023-06-15',
    //       carModelId: 2,
    //       carId: 9,
    //       driverId: 3,
    //       car: {
    //         id: 9,
    //         modelId: 2,
    //         color: 'ขาว',
    //         licensePlateId: '9 กข 3776 กรุงเทพมหานคร',
    //         model: 'Toyota Sienta',
    //         seat: 4,
    //         imageUrl: 'https://backoffice-dev-sm-t-mobility.toyota.co.th/public/cars/smv_bkk/toyota-sienta.png',
    //       },
    //     },
    //     availability: {
    //       isAvailable: false,
    //       isGoTripAvailable: false,
    //       isBackTripAvailable: false,
    //       goTripAvailableTime: [
    //         {
    //           earliest: dayjstz("2023-06-16T09:37:00.000Z").toDate(),
    //           latest: dayjstz("2023-06-16T11:30:36.812Z").toDate()
    //         }
    //       ],
    //       backTripAvailableTime: [
    //           {
    //             earliest: dayjstz("2023-06-16T12:37:00.000Z").toDate(),
    //             latest: dayjstz("2023-06-16T16:30:36.812Z").toDate()
    //           }
    //       ]
    //   },
    //     isFree: false,
    //   },
    // ]
  }

  async function getVehicle(vehicleId: number) {
    const url = `${baseUrl}/liff-cars/vehicles/${vehicleId}`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers })
    return data
  }

  async function getVehicleState(vehicleId: number) {
    const url = `${baseUrl}/liff-cars/vehicles-state/${vehicleId}`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers })
    return data
  }

  async function getCarModels(): Promise<{ carModels: CarModel[]; count: number }> {
    const url = `${baseUrl}/liff-cars/models`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers })
    return data
  }

  async function getCarModelPrice(carModelId: number, distance: number): Promise<number> {
    const url = `${baseUrl}/liff-cars/price`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers, params: { carModelId, distance } })
    return Number(data?.price ?? null)
  }

  // Customer ------------------------------------------------------------------

  async function getAcceptConsent(lineId: string): Promise<{ consent: IConsent; showRequestTrial: boolean } | null> {
    const url = `${baseUrl}/liff-customers/consent`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers, params: { lineid: lineId } })
    return data
  }

  async function createAcceptConsent(lineId: string) {
    const url = `${baseUrl}/liff-customers/consent`
    const headers = generateHeader()
    const data = await axios.post(url, { lineId }, { headers })
    return data
  }

  async function createRequestTrial(
    requestTrial: IRequestTrial
  ): Promise<{ success: boolean; request: IRequestTrial }> {
    const url = `${baseUrl}/liff-customers/requests`
    const headers = generateHeader()
    const { data } = await axios.post(url, requestTrial, { headers })

    return data
  }

  async function getCustomer(params?: object): Promise<BookerContact | null> {
    const url = `${baseUrl}/liff-customers/user`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers, params })
    return data ? (data as BookerContact) : null
  }

  // same as getCustomer, but CNMI customer has different data type
  async function getCustomerCNMI(params?: object): Promise<CustomerCNMIInterface | null> {
    const url = `${baseUrl}/liff-customers/userCNMI`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers, params })
    return data ? (data as CustomerCNMIInterface) : null
  }

  async function updateCustomer(c: UpdateCustomer) {
    const url = `${baseUrl}/liff-customers/user`
    const headers = generateHeader()
    const { data } = await axios.put(url, c, { headers })
    return data.message
  }

  async function createCustomerCNMI(c: CreateCustomer) {
    const url = `${baseUrl}/liff-customers/user`
    const headers = generateHeader()
    const res = await axios.post(url, c, { headers })
    console.log('res', res)
    return res
  }

  async function updateCustomerCNMI(c: CustomerCNMIInterface) {
    const url = `${baseUrl}/liff-customers/userCNMI`
    const headers = generateHeader()
    const { data } = await axios.put(url, c, { headers })
    return data.message
  }

  async function updateContactHistoryCNMI(c: CustomerAddressCNMIInterface) {
    const url = `${baseUrl}/liff-customers/userContactCNMI`
    const headers = generateHeader()
    const { data } = await axios.put(url, c, { headers })
    return data.message
  }

  async function deleteContactHistoryCNMI(c: CustomerAddressCNMIInterface) {
    const url = `${baseUrl}/liff-customers/userContactCNMI/delete`
    const headers = generateHeader()
    const { data } = await axios.put(url, c, { headers })
    return data.message
  }

  async function updateAddressHistoryCNMI(c: CustomerAddressCNMIInterface) {
    const url = `${baseUrl}/liff-customers/userAddressCNMI`
    const headers = generateHeader()
    const { data } = await axios.put(url, c, { headers })
    return data.message
  }

  async function deleteAddressHistoryCNMI(c: CustomerAddressCNMIInterface) {
    const url = `${baseUrl}/liff-customers/userAddressCNMI/delete`
    const headers = generateHeader()
    const { data } = await axios.put(url, c, { headers })
    return data.message
  }

  async function getAddressHistoryCNMI(params?: object): Promise<BookerContactCNMI | null> {
    const url = `${baseUrl}/liff-customers/userAddressCNMI`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers, params })
    return data ? (data as BookerContactCNMI) : null
  }

  async function updateRequestById(id: number, bookingNumber: string): Promise<{ success: boolean }> {
    const url = `${baseUrl}/liff-customers/requests/${id}`
    const headers = generateHeader()
    const { data } = await axios.put(url, { bookingNumber }, { headers })
    return data
  }

  // Destination --------------------------------------------------------------------

  async function getDestinationList(lang: string): Promise<Place[]> {
    const url = `${baseUrl}/liff-hospital/destinationList/${lang}`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers })
    // const data = [
    //   {
    //     id: 'ChIJU9rWFcFhHTERrqff1zZRn-8',
    //     name: 'โรงพยาบาลสมิติเวช ศรีนครินทร์',
    //     address: '488 ถนน ศรีนครินทร์ แขวง สวนหลวง แขวงสวนหลวง กรุงเทพมหานคร 10250 ประเทศไทย',
    //     lat: '13.7457342',
    //     lng: '100.6432621',
    //     note: undefined
    //   },
    //   {
    //    id: '000000000000000000000000000',
    //    name: 'อื่นๆ',
    //    address: undefined,
    //    lat: undefined,
    //    lng: undefined,
    //    note: undefined
    //   },
    //   {
    //    id: 'ChIJm3xGHNWe4jARqzgYCjBzeJU',
    //    name: 'โรงพยาบาล 2',
    //    address: 'address 2',
    //    lat: '13.7457152',
    //    lng: '100.8132617',
    //    note: undefined
    //   },
    //   {
    //    id: 'ChIJj0aeFIaZ4jAR9jXvvKsxiWA',
    //    name: 'โรงพยาบาล 3',
    //    address: 'address 3',
    //    lat: '13.7957545',
    //    lng: '100.5532649',
    //    note: undefined
    //   },
    //   {
    //    id: 'ChIJgV-m0NuY4jARUgGR6NAwRN8',
    //    name: 'โรงพยาบาล 4',
    //    address: 'address 4',
    //    lat: '13.9457372',
    //    lng: '100.6432431',
    //    note: undefined
    //   }
    // ]
    return data
  }

  // Driver --------------------------------------------------------------------

  async function getDriverById(id: string) {
    const url = `${baseUrl}/liff-drivers/${id}`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers })
    return data
  }

  // Feedback ------------------------------------------------------------------

  async function getPublicFeedbacks(domain: string, params?: object): Promise<IFeedback[]> {
    const url = `${baseUrl}/public-feedbacks?domain=${domain}`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers, params })
    return data
  }

  async function createFeedback(feedback: PostFeedback) {
    const url = `${baseUrl}/liff-feedbacks`
    const headers = generateHeader()
    const data = await axios.post(url, feedback, { headers })
    return data
  }

  // Location ------------------------------------------------------------------

  async function getDriverLocation(bookingNumber: string, destination: string): Promise<DriverLocation> {
    const url = `${baseUrl}/location/${bookingNumber}?destination=${destination}`
    const headers = generateHeader()
    const { data } = await axios.get(url, { headers })
    return data
  }

  // Payment -------------------------------------------------------------------

  async function updatePayment(
    id: string,
    body: { bookingNumber: string; method: string }
  ): Promise<{ success: boolean }> {
    const url = `${baseUrl}/liff-payments/${id}`
    const headers = generateHeader()
    const { data } = await axios.put(url, body, { headers })
    return data
  }

  async function getDomain() {
    const headers = generateHeader()
    const url = `${baseUrl}/liff-hospital/domain`
    const { data } = await axios.get(url, { headers })
    return data
  }

  async function getPaymentSummary(params?: object): Promise<Bookings | null> {
    const url = `${baseUrl}/liff-payments/paymentsummary`
    const headers = generateHeader()
    const config: object = {
      method: 'get',
      url,
      headers,
      params,
    }
    const { data } = await axios(config)
    return data
  }

  async function getPaymentMethod(): Promise<PaymentMethod> {
    const headers = generateHeader()
    const url = `${baseUrl}/liff-payments/payment-method`
    const { data } = await axios.get(url, { headers })
    return data
  }

  // Question ------------------------------------------------------------------

  async function getQuestions(domain: string): Promise<Questions> {
    const url = `${baseUrl}/liff-questions?domain=${domain}`
    const headers = generateHeader()
    const config: object = {
      method: 'get',
      url,
      headers,
    }
    const { data } = await axios(config)
    return data
  }

  // Staff ---------------------------------------------------------------------

  async function getStaff(domain: string) {
    try {
      const url = `${baseUrl}/staff?domain=${domain}`
      const { data } = await axios.get(url)
      return data
    } catch (e) {
      return undefined
    }
  }

  // MED CNMI --------------------------------------------------------------------

  async function getAppointment() {
    try {
      const url = `${baseUrl}/liff-hospital/appointment`
      const { data } = await axios.get(url)
      return data
    } catch (e) {
      return e
    }
  }

  // ---------------------------------------------------------------------------

  return {
    signin,

    getBookings,
    getBooking,

    checkSlotAvailability,

    getPublicBooking,
    createBooking,
    createRealtimeBooking,
    updateBooking,
    updatePassengerLineId,
    cancelBooking,

    getRealtimeStatus,

    getCountActive,
    getVehicles,
    getAvailableVehicles,
    getVehicle,
    getVehicleState,
    getCarModels,
    getCarModelPrice,

    getAcceptConsent,
    createAcceptConsent,
    createRequestTrial,
    getCustomer,
    getCustomerCNMI,
    updateCustomer,
    createCustomerCNMI,
    updateRequestById,
    updateCustomerCNMI,
    updateContactHistoryCNMI,
    deleteContactHistoryCNMI,
    updateAddressHistoryCNMI,
    getAddressHistoryCNMI,
    deleteAddressHistoryCNMI,

    getStartStopCarServiceTime,
    getCutOffAdvanceBookingTime,

    getSupportPostcode,
    getSupportProvince,
    getNormalizeProvince,

    getDestinationList,

    getDriverById,

    getPublicFeedbacks,
    createFeedback,

    getDriverLocation,

    updatePayment,

    getQuestions,

    getStaff,

    getDomain,

    getPaymentMethod,

    getAppointment,
    getBookingList,
    getPaymentSummary,
  }
}
